<template>
  <v-container class="container--fluid fill-height primary">
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
      >
        <v-card class="elevation-5 px-3 pb-3">
          <v-card-text>
            <div class="layout column align-center">
              
              <h1 class="text-center mt-4 mb-8 primary--text">
                Nastavenie hesla
              </h1>

            </div>
            <v-form>
              <v-text-field
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    name="password"
                    label="Heslo"
                    hint="Minimálne 8 znakov"
                    persistent-hint
                    :error-messages="passwordErrors"
                    @click:append="show = !show"
                    @keyup.enter="setPassword"
                ></v-text-field>

                <v-text-field
                    v-model="password2"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    name="password2"
                    label="Potvrdenie"
                    hint="Minimálne 8 znakov"
                    persistent-hint
                    :error-messages="password2Errors"
                    @click:append="show2 = !show2"
                    @keyup.enter="setPassword"
                ></v-text-field>
            </v-form>
          </v-card-text>
          
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="setPassword"
              :loading="loading"
            >
              Uložiť
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


    <v-snackbar
        v-model="snackbar"
        top
        centered
        color="error"
        
      >
      {{ snackbar_msg }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="error"
          depressed
          v-bind="attrs"
          @click="snackbar = false"
        >
          Zatvoriť
        </v-btn>
      </template>

  </v-snackbar>

  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

import axios from 'axios'

import { set_password } from '@/data/api'

export default {
    name: 'SetPassword',

    mixins: [validationMixin],

    data(){
      return {
        token: "",

        password: "",
        show: false,

        password2: "",
        show2: false,

        snackbar: false,
        snackbar_msg: "",

        loading: false
      }
    },

    validations: {
      password:{
        required,
        minLength: minLength(8)
      },
      password2:{
        sameAsPassword: sameAs('password')
      }
    },

    computed:{
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.minLength && errors.push('Minimálne 8 znakov')
        !this.$v.password.required && errors.push('Toto pole je povinné')
        return errors
      },

      password2Errors(){
        const errors = []
        if(!this.$v.password2.$dirty) return errors
        !this.$v.password2.sameAsPassword && errors.push('Heslá sa musia zhodovať')
        return errors
      }
    },

    methods: {
        async setPassword(){
            this.$v.$touch()

            if(!this.$v.$invalid){
              this.loading = true
              await axios.post(set_password(),
              {
                  token: this.token,
                  password: this.password
              },
              {
                  headers:{
                      'Content-Type' : 'application/json',
                  },
              },  
              ).then(() => {
                  this.$router.push({name: "Login"})
                }
              ).catch(error => {
                if(error.response.status == 400){
                  this.snackbar_msg = "Heslo už bolo nastavené preto ho nemožno zmeniť"
                  this.snackbar = true
                  
                }else{
                  this.snackbar_msg = "Vyskytla sa neočakávaná chyba"
                  this.snackbar = true
                  
                }
              })

              this.loading = false
            }
        }
    },
    mounted(){ 
        if(this.$store.getters.loggedIn){
            this.$store.dispatch('Logout')
        }
    },
    created(){
      this.token = this.$route.query.token

      if(typeof this.token === "undefined"){
          this.$router.back()
      }
    }
};
</script>